<template>
    <div>
        <main class="container-fluid">
            <div class="row mx-md-4">
				<!-- Breadcrumb -->
				<div class="col-md-12 mb-4">
					<div class="card shadow border-0 round">
						<div class="card-body px-2 py-1">
							<nav aria-label="breadcrumb">
								<ol class="breadcrumb bg-transparent mb-0">
									<li class="breadcrumb-item text-info"><router-link to="/" class=" text-decoration-none">Home</router-link></li>
									<li class="breadcrumb-item active" aria-current="page">Syarat dan Ketentuan</li>
								</ol>
							</nav>
						</div>
					</div>
				</div>
				
				<div class="col-md-3 mb-4">
					<SidebarPanduan />
				</div>
				
				<div class="col-md-9 mb-4">
					<div class="card shadow border-0 text-secondary round px-5 py-4">
						<div class="text-center mb-4">
							<h2>Syarat dan Ketentuan</h2>
						</div>
						<div class="text-left">
							<p>Syarat dan Ketentuan berbelanja dan beraktifitas di duniamurah.com</p>
							<ul>
								<li>Hindarkan Fraud atau penipuan. Segala hal yang berhubungan dengan fraud dan penipuan akan kami laporkan kepihak yang berwajib</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
        </main>
    </div>
</template>

<script>
import SidebarPanduan from '@/components/SidebarPanduan.vue'

export default {
	components: {
  		SidebarPanduan
	},
	created() {
		$(document).scrollTop(0)
	} 
}
</script>